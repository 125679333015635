import Vue from "vue";
import App from "./App.vue";
import vuetify from './plugins/vuetify'
import VueCookies from "vue-cookies";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: (h) => h(App)
}).$mount("#app");

Vue.use(VueCookies)
Vue.$cookies.config("1000y");

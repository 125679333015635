<template>
  <div class="step-container">
    <div :class="{ prev: phase > 0, current: phase === 0, next: phase < 0 }">
      <div class="text-info">정보입력</div>
    </div>
    <div
      :class="{
        'line-prev': phase > 1,
        'line-current': phase === 1,
        'line-next': phase < 1,
      }"
    ></div>
    <div :class="{ prev: phase > 1, current: phase === 1, next: phase < 1 }">
      <div class="text-info">계좌 송금</div>
    </div>

    <div
      :class="{
        'line-prev': phase > 2,
        'line-current': phase === 2,
        'line-next': phase < 2,
      }"
    ></div>
    <div :class="{ prev: phase > 2, current: phase === 2, next: phase < 2 }">
      <div class="text-info">계좌 인증</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    phase: Number,
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  color: #363636;
}

.step-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  font-weight: 500;
  font-size: 14px;

  .prev {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #373eff;
  }

  .current {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 2px solid #373eff;
    color: #373eff;
  }

  .next {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 2px solid #ced1d2;
  }

  .line-prev {
    width: 112px;
    border-top: 1px solid #373eff;
  }

  .line-current {
    width: 112px;
    border-top: 1px dashed #363636;
  }

  .line-next {
    width: 112px;
    border-top: 1px dashed #ced1d2;
  }

  .text-info {
    position: absolute;
    width: 100px;
    top: 22px;
    left: -45px;
    text-align: center;
  }
}
</style>

<template>
  <div
    class="container"
    style="
      padding: 0px 30px 40px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      
    position: relative;
    "
  >
    <Step :phase="0"></Step>

    <div
      style="text-align: center; margin-bottom: 2rem; margin: 80px auto 30px;"
    >
      <h2 class="bank-title">계좌 소유자 입력창</h2>
      <label class="bank-label">테스트를 위한 계좌 소유자 입력 창입니다.</label>
    </div>
    <v-text-field
      placeholder="계좌 소유자 정보 입력"
      class="bank-account-input"
      style="flex: 1; box-shadow: none; width: 100%;font-size:20px;"
      solo
      outlined
      @input="onInput"
    ></v-text-field>
    <div
      class="btn-container"
      style="display: flex; position: sticky; bottom: 0px; left: 0px"
    >
      <v-btn :loading="loading" @click="onClickNext" block color="#373eff">
        확인
      </v-btn>
    </div>
  </div>
</template>
<script>
import Step from "@/components/Step";
export default {
  components: {
    Step,
  },
  props: { appData: Object },
  data() {
    return {
      loading: false,
      account_holder_name: "",
    };
  },

  methods: {
    onClickNext() {
      this.$emit("next", { account_holder_name: this.account_holder_name });
    },
    onInput(e) {
      this.account_holder_name = e;
    },
  },
};
</script>
<style lang="scss" scoped>
.bank-title {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;

  color: #363636;
}
.bank-label {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #868686;
}
.icon-close {
  position: absolute;
  width: 32px;
  right: 32px;
  top: 32px;
  cursor: pointer;
}
.card-result-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 32px;
  text-align: center;
}
.bank_body {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  display: flex;
}
.bank_account_display {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  div {
    display: flex;
    align-items: center;
  }
  .bank_account_num {
    /* KR/Body 5 - 14 */
    margin-left: 0.5rem;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #868686;
  }
}
.bank_verify_num {
  margin: 1.5rem 0px;

  input {
    width: 62px;
    height: 62px;
    margin: 5px;

    border: 1px solid #ced1d2;
    box-sizing: border-box;
    /* 2 dp, Primary */

    box-shadow: 0px 2px 8px 4px rgba(95, 101, 255, 0.05);
    filter: drop-shadow(0px 0px 8px rgba(95, 101, 255, 0.1));
    border-radius: 4px;
    text-align: center;

    //input value control
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height, or 150% */

    text-align: center;

    /* On Surface/Text / High Emphasis */

    color: #363636;
    &.focus {
      border: 1px solid #5f65ff;
    }
    &:focus-visible {
      border: 1px solid #5f65ff;
    }
  }
}
.bank_verify_label {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;

  /* Gray/500 */

  color: #abacad;
}
.btn-container {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(0, 0, 0, 0.12) !important;
    background-color: #d7d8ff !important;
    color: #fff !important;
  }
  button {
    //size styling
    height: 60px !important;
    padding: 15px 16px;
    border-radius: 8px;

    //color styling
    background: #373eff;

    //text styling
    color: #fff;
    font-family: Noto Sans KR;
    font-style: normal;
    /* font-weight: 500; */
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 29px;
  }
}
.btn-container {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(0, 0, 0, 0.12) !important;
    background-color: #d7d8ff !important;
    color: #fff !important;
  }
  button {
    //size styling
    height: 60px !important;
    padding: 15px 16px;
    border-radius: 8px;

    //color styling
    background: #373eff;

    //text styling
    color: #fff;
    font-family: Noto Sans KR;
    font-style: normal;
    /* font-weight: 500; */
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 29px;
  }
}
</style>

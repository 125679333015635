<template>
  <v-app id="app" ref="app">
    <SampleAccountHolder
      v-if="phase === PHASE_BANK_HOLDER"
      :phase="phase"
      @next="onNext"
      @cancel="onCancel"
    >
    </SampleAccountHolder>
    <BankSelect
      v-if="phase === PHASE_BANK_SEND"
      :phase="phase"
      :appData="appData"
      :selectBank="selectBank"
      @next="onNext"
      @cancel="onCancel"
    ></BankSelect>
    <BankVerify
      v-if="phase === PHASE_BANK_VERIFY"
      :appData="appData"
      @next="onNext"
      @cancel="onCancel"
      @select="onSelect"
    ></BankVerify>
    <VerifySuccess @cancel="onCancel" v-if="phase === PHASE_VERIFY_SUCCESS">
    </VerifySuccess>
    <VerifyTryOut
      @cancel="onCancel"
      v-if="phase === PHASE_VERIFY_Fail"
    ></VerifyTryOut>
  </v-app>
</template>

<script>
import BankSelect from "./components/auth/BankSelect";
import BankVerify from "./components/auth/BankVerify";
import VerifySuccess from "./components/auth/VerifySuccess";
import SampleAccountHolder from "./components/auth/SampleAccountHolder";
import VerifyTryOut from "./components/auth/VerifyTryOut";

export default {
  name: "App",

  components: {
    BankSelect,
    BankVerify,
    VerifySuccess,
    VerifyTryOut,
    SampleAccountHolder,
  },

  data: () => ({
    PHASE_BANK_HOLDER: -1,
    PHASE_BANK_SEND: 0,
    PHASE_BANK_VERIFY: 1,
    PHASE_VERIFY_SUCCESS: 2,
    PHASE_VERIFY_Fail: 3,
    phase: -1,

    hegiht: 0,
    appData: {},
    selectBank: false,
  }),

  methods: {
    onNext(data) {
      if (this.phase === this.PHASE_BANK_SEND) {
        this.appData = {
          account_holder_name: this.appData.account_holder_name,
          ...data,
        };
        this.phase = this.PHASE_BANK_VERIFY;
      } else if (this.phase === this.PHASE_BANK_VERIFY) {
        if (data) {
          if (data.fail) {
            this.phase = this.PHASE_VERIFY_Fail;
            return;
          }
        }
        this.phase = this.PHASE_VERIFY_SUCCESS;
      } else if (this.phase === this.PHASE_BANK_HOLDER) {
        this.appData = {
          account_holder_name: this.appData.account_holder_name,
          ...this.appData,
          ...data,
        };
        this.phase = this.PHASE_BANK_SEND;
      }
    },
    onCancel(data) {
      this.phase = data.phase;
      if (data.selectBank) {
        this.selectBank = data.selectBank;
      }
    },
    onSelect() {
      this.phase = 0;
    },
  },
};
</script>
<style scoped lang="scss">
* {
  font-family: Noto Sans KR, Apple SD Gothic Neo, Sans-serif;
}

.v-application--wrap {
  min-height: auto !important;
}
#app {
  position: relative;

  height: 100%;
  color: #363636;
  /* margin-top: 50px; */
}
ul {
  padding-left: 0;
}
@media (min-width: 800px) {
  .container {
    width: 800px !important;
    margin: auto !important;
  }
}
</style>

<template>
  <div
    class="container"
    style="padding: 0 30px 40px; flex: 1; display: flex; flex-direction: column;height:100%;"
  >
    <div class="card-result-title">계좌인증</div>
    <div class="openbank-spacer" style="padding-top: 100px"></div>
    <div class="body-container" style="text-align: center; margin-bottom: 0rem">
      <v-img
        max-height="181"
        max-width="234"
        :transition="false"
        style="margin: 0 auto"
        src="@/assets/verify_fail.svg"
      ></v-img>
      <h2 class="bank-title">본인 인증 실패</h2>
      <label class="bank-label"
        >인증코드 입력 횟수가 초과되었습니다. <br />
        인증코드를 다시 요청 해주세요.
      </label>
      <div class="card-count warn">
        시도 횟수 : 3/3
      </div>
    </div>
    <div class="btn-container">
      <v-btn @click="onClickBack" block color="#373eff"> 종료 </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {};
  },
  components: {},
  methods: {
    onClickBack() {
      this.$emit("cancel",{phase:-1});
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 800px) {
  .container {
    width: 800px !important;
    margin: auto !important;
  }
}
.body-container {
  flex: 1;
  .bank-title {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 42px;

    color: #373eff;
    margin-top: 30px;
    margin-bottom: 1.5rem;
  }
  .bank-label {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 26px;

    color: #363636;
  }
}
.card-result-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 32px;
  text-align: center;
}
.card-count {
  /* KR / Body 3 - 16 */

  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #868686;
  margin: 8px 0px;
  margin-bottom: 2rem;
  &.warn {
    color: #f53c3c;
  }
}
.btn-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  button {
    //size styling
    height: 60px !important;
    padding: 15px 16px;
    border-radius: 8px;

    //color styling
    background: #373eff;

    //text styling
    color: #fff;
    font-family: Noto Sans KR;
    font-style: normal;
    /* font-weight: 500; */
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 29px;
  }
}
</style>

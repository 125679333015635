<template>
  <div
    class="container"
    style="
      padding: 0px 30px 40px;
      flex: 1;
      display: flex;
      flex-direction: column;
      height:100%;
      align-items: center;
    "
  >
    <img
      @click="stopScan = true"
      src="@/assets/icon-close-32.svg"
      class="icon-close"
    />
    <div class="card-result-title">계좌인증</div>

    <div
      style="text-align: center; margin-bottom: 2rem; margin: 55px auto 30px"
    >
      <h2 class="bank-title">1원을 보냈습니다.</h2>
      <label class="bank-label"
        >입금내역에 표시된 숫자 4자리를 입력해주세요.</label
      >
    </div>
    <div class="bank_body" style="">
      <v-img
      :transition="false"
        
        max-height="165"
        max-width="212"
        :src="require('@/assets/bank_history.svg')"
      ></v-img>
      <div class="bank_account_display">
        <div>
          <v-img
          :transition="false"
            v-if="appData.tabIdx === 0"
            
            max-height="16"
            max-width="16"
            :src="
              require('@/assets/' +
                CONSTANTS.BANK_NAME[appData.bankIdx].img)
            "
          ></v-img>
          <v-img
          :transition="false"
            v-if="appData.tabIdx === 1"
            
            max-height="16"
            max-width="16"
            :src="
              require('@/assets/' +
                CONSTANTS.BROKERAGE[appData.bankIdx].img)
            "
          ></v-img>
          <div class="bank_account_num">
            {{ appData.account_num }}
          </div>
        </div>
        <v-btn
          color="#868686"
          class="bank_change_btn"
          text
          @click="onClickChangeBank"
          >변경하기</v-btn
        >
      </div>
    </div>
    <div class="bank_verify_num">
      <input
        type="number"
        name="input1"
        @blur="onBlur"
        pattern="\d*"
        ref="input1"
        :class="{ focus: this.inputFocus.num1 }"
        maxlength="1"
        @focus="onFocus"
        @input="onChange"
      />
      <input
        type="number"
        pattern="\d*"
        @blur="onBlur"
        name="input2"
        ref="input2"
        :class="{ focus: this.inputFocus.num2 }"
        maxlength="1"
        @focus="onFocus"
        @input="onChange"
      />
      <input
        type="number"
        pattern="\d*"
        @blur="onBlur"
        name="input3"
        ref="input3"
        :class="{ focus: this.inputFocus.num3 }"
        maxlength="1"
        @focus="onFocus"
        @input="onChange"
      />
      <input
        type="number"
        pattern="\d*"
        @blur="onBlur"
        name="input4"
        ref="input4"
        :class="{ focus: this.inputFocus.num4 }"
        maxlength="1"
        @focus="onFocus"
        @input="onChange"
      />
    </div>
    <div class="bank_verify_label">
      입금내역이 없다면 등록하신 계좌 정보를 다시 확인해주세요.
    </div>
    <div
      class="btn-container bank-verify-btn-container"
      :class="{ sticky: verifyBtnPosition }"
      style="
        display: flex;

        
        bottom: 0px;
        left: 0px;
      "
    >
      <v-btn
        :loading="loading"
        :disabled="disabled"
        @click="verify"
        ref="verifyBtn"
        block
        color="#373eff"
      >
        확인
      </v-btn>
    </div>
    <ExitDialog v-model="stopScan" @ok="onClickBack"></ExitDialog>
    <VerifyFailDialog
      v-model="verifyConfirm"
      @ok="onClickExit"
      @retry="onSelectBank"
      @cancel="onCancel"
      :verifyCount="verifyCount"
    ></VerifyFailDialog>
    <SystemErrorDialog
      v-model="systemError"
      @ok="onClickExit"
    ></SystemErrorDialog>
    <ChangeBankDialog
      v-model="changeBank"
      @retry="onSelectBank"
    ></ChangeBankDialog>
  </div>
</template>
<script>
import ExitDialog from "../ExitDialog";
import SystemErrorDialog from "../SystemErrorDialog";
import VerifyFailDialog from "../VerifyFailDialog";
import ChangeBankDialog from "../ChangeBankDialog.vue";
import CONSTANTS from "@/constants";
import EventBus from "@/api/EventBus";
// ! needs to change module import useb from "@/api/useb";
import useb from "@/api/useb";

export default {
  components: {
    ExitDialog,
    VerifyFailDialog,
    ChangeBankDialog,
    SystemErrorDialog,
  },
  props: {
    appData: Object,
  },
  data() {
    return {
      loading: false,
      verifyBtnPosition: true,
      verifyCount: 0,
      verifyConfirm: false,
      systemError: false,
      changeBank: false,
      disabled: true,
      verifyData: {
        transaction_id: "",
        print_content: "",
      },
      stopScan: false,
      CONSTANTS: CONSTANTS,
      inputFocus: {
        num1: false,
        num2: false,
        num3: false,
        num4: false,
      },
    };
  },
  created() {
    this.initialize();
  },
  mounted() {
    this.initialize();
    this.$refs.input1.focus();
  },
  methods: {
    onBlur(e) {
      this.verifyBtnPosition = true;
      if (
        this.$refs.input1.value &&
        this.$refs.input2.value &&
        this.$refs.input3.value &&
        this.$refs.input4.value
      ) {
        this.disabled = false;
      } else {
        this.diabled = true;
      }
      if (!this.$refs[e.target.name].value) {
        switch (e.target.name) {
          case "input1":
            this.$set(this.inputFocus, "num1", false);
            break;
          case "input2":
            this.$set(this.inputFocus, "num2", false);
            break;
          case "input3":
            this.$set(this.inputFocus, "num3", false);
            break;
          case "input4":
            this.$set(this.inputFocus, "num4", false);
            break;
        }
      }
    },
    onCancel() {
      if (this.verifyCount >= 3) {
        this.$emit("cancel", { selectBank: true });
      }
    },
    onClickBack() {
      this.$emit("cancel",{phase:0});
    },

    async verify() {
      this.loading = true;
      this.disabled = true;
      try {
        this.$set(
          this.verifyData,
          "print_content",
          this.$refs.input1.value +
            this.$refs.input2.value +
            this.$refs.input3.value +
            this.$refs.input4.value
        );
        // * test code for success
        // this.$emit("next");
        // return;
        // * test code for fail

        // throw new Error("테스트용 에러코드")

        // * service code
        await useb.verifyOpenbank(this.verifyData);
        this.$emit("next");
        this.loading = false;
        this.disabled = false;
      } catch (err) {
        const { errorCode } = err;
        if (errorCode !== "V001") {
          this.systemError = true;
        } else {
          this.verifyConfirm = true;
          this.verifyCount += 1;
          if (this.verifyCount === 3) {
            this.$emit("next", { fail: true });
          }
        }
        this.loading = false;
        this.disabled = false;
      }
    },
    onClickExit() {
      this.stopScan = true;
    },
    initialize() {
      this.appData.transaction_id;
      this.appData.account_num;
      this.appData.bankIdx;
      this.appData.tabIdx;
      this.$set(this.verifyData, "transaction_id", this.appData.transaction_id);
    },
    onClickChangeBank() {
      this.changeBank = true;
    },
    onSelectBank() {
      EventBus.$emit("select");
      this.$emit("cancel", { selectBank: true,phase:0 });
    },
    onFocus(e) {
      this.verifyBtnPosition = false;
      if (
        this.$refs.input1.value &&
        this.$refs.input2.value &&
        this.$refs.input3.value &&
        this.$refs.input4.value
      ) {
        this.disabled = false;
      } else {
        this.diabled = true;
      }
      switch (e.target.name) {
        case "input1":
          this.$set(this.inputFocus, "num1", true);
          this.$refs.input1.value = "";
          break;
        case "input2":
          this.$set(this.inputFocus, "num2", true);
          this.$refs.input2.value = "";
          break;
        case "input3":
          this.$set(this.inputFocus, "num3", true);
          this.$refs.input3.value = "";
          break;
        case "input4":
          this.$set(this.inputFocus, "num4", true);
          this.$refs.input4.value = "";
          break;
        default:
          break;
      }
    },
    onChange(e) {
      if (
        this.$refs.input1.value &&
        this.$refs.input2.value &&
        this.$refs.input3.value &&
        this.$refs.input4.value
      ) {
        this.disabled = false;
      } else {
        this.diabled = true;
      }
      switch (e.target.name) {
        case "input1":
          this.$refs.input2.value = "";
          this.$refs.input2.focus();
          break;
        case "input2":
          this.$refs.input3.value = "";
          this.$refs.input3.focus();
          break;
        case "input3":
          this.$refs.input4.value = "";
          this.$refs.input4.focus();
          break;
        case "input4":
          if (this.$refs.input4.value.length > 1) {
            this.$refs.input4.value = this.$refs.input4.value.slice(0, 1);
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss">
@media (min-width: 800px) {
  .container {
    width: 800px !important;
    margin: auto !important;
  }
}
.bank-title {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;

  color: #363636;
}
.bank-label {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #868686;
}
.bank_change_btn span {
  font-size: 14px;
}
.icon-close {
  position: absolute;
  width: 32px;
  right: 32px;
  top: 32px;
  cursor: pointer;
}
.card-result-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 32px;
  text-align: center;
}
.bank_body {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  display: flex;
}
.bank_account_display {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  div {
    display: flex;
    align-items: center;
  }
  .bank_account_num {
    /* KR/Body 5 - 14 */
    margin-left: 0.5rem;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #868686;
  }
}
div.bank_account_display > button > span {
  font-size: 14px;
}
.bank_chagne_btn span {
  font-size: 14px !important;
}

@media (max-width: 350px) {
  .bank_verify_num {
    input {
      width: 50px !important;
      height: 50px !important;
    }
  }
}

.bank_verify_num {
  margin: 1.5rem 0px 11px;
  // width:80%;
  // max-width:350px;
  input {
    width: 62px;
    height: 62px;

    margin: 5px;

    border: 1px solid #ced1d2;
    box-sizing: border-box;
    /* 2 dp, Primary */

    box-shadow: 0px 2px 8px 4px rgba(95, 101, 255, 0.05);
    filter: drop-shadow(0px 0px 8px rgba(95, 101, 255, 0.1));
    border-radius: 4px;
    text-align: center;

    //input value control
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height, or 150% */

    text-align: center;
    outline: none;
    /* On Surface/Text / High Emphasis */

    color: #363636;
    &.focus {
      border: 1px solid #5f65ff;
      outline: none;
    }
    &:focus-visible {
      border: 1px solid #5f65ff;
      outline: none;
    }
  }
}
.bank_verify_label {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;

  /* Gray/500 */

  color: #abacad;
}

.bank-verify-btn-container .v-btn__content {
  color: #fff;
}

.btn-container {
  flex: 1;
  width: 100%;
  position: static;
  &.sticky {
    position: sticky;
  }
  display: flex;
  align-items: flex-end;
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(0, 0, 0, 0.12) !important;
    background-color: #d7d8ff !important;
    color: #fff !important;
  }
  button {
    //size styling
    height: 60px !important;
    padding: 15px 16px;
    border-radius: 8px;

    //color styling
    background: #373eff;

    //text styling
    color: #fff;
    font-family: Noto Sans KR;
    font-style: normal;
    /* font-weight: 500; */
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 29px;
  }
}
</style>

export default Object.freeze({
  BANK_NAME: [
    {
      name: "NH 농협",
      img: "bank-01-NH.svg",
      bank_code: "011",
    },
    {
      name: "우리",
      img: "bank-02-woori.svg",
      bank_code: "020",
    },
    {
      name: "신한",
      img: "bank-18-jeju.svg",
      bank_code: "088",
    },
    {
      name: "KB국민",
      img: "bank-04-kb.svg",
      bank_code: "004",
    },
    {
      name: "하나",
      img: "bank-05-hana.svg",
      bank_code: "081",
    },
    {
      name: "시티",
      img: "bank-06-citi.svg",
      bank_code: "027",
    },
    {
      name: "IBK기업",
      img: "bank-07-ibk.svg",
      bank_code: "003",
    },
    {
      name: "케이뱅크",
      img: "bank-08-kbank.svg",
      bank_code: "089",
    },
    {
      name: "카카오뱅크",
      img: "bank-09-kakao.svg",
      bank_code: "090",
    },
    {
      name: "새마을",
      img: "bank-10-mg.svg",
      bank_code: "045",
    },
    {
      name: "부산",
      img: "bank-11-bs.svg",
      bank_code: "032",
    },
    {
      name: "경남",
      img: "bank-12-kn.svg",
      bank_code: "039",
    },
    {
      name: "광주",
      img: "bank-13-kj.svg",
      bank_code: "034",
    },
    {
      name: "전북",
      img: "bank-14-jb.svg",
      bank_code: "037",
    },
    {
      name: "신협",
      img: "bank-15-shinhyup.svg",
      bank_code: "048",
    },
    {
      name: "SC제일",
      img: "bank-15-sc.svg",
      bank_code: "023",
    },
    {
      name: "KDB산업",
      img: "bank-16-kdb.svg",
      bank_code: "002",
    },
    {
      name: "대구",
      img: "bank-17-dgb.svg",
      bank_code: "031",
    },
    {
      name: "제주",
      img: "bank-18-jeju.svg",
      bank_code: "035",
    },
    {
      name: "우체국",
      img: "bank-19-post.svg",
      bank_code: "071",
    },
    {
      name: "수협",
      img: "bank-20-sh.svg",
      bank_code: "007",
    },
    {
      name: "저축",
      img: "bank-21-sb.svg",
      bank_code: "050",
    },
    {
      name: "산림",
      img: "bank-22-sanrim.svg",
      bank_code: "064",
    },
    {
      name: "SBI저축은행",
      img: "bank-23-sbi.svg",
      bank_code: "103",
    },
  ],
  BROKERAGE: [
    {
      name: "토스증권",
      img: "sec-01-toss.svg",
      bank_code: "271",
    },
    {
      name: "키움",
      img: "sec-02-kiwoom.svg",
      bank_code: "264",
    },
    {
      name: "미래에셋대우",
      img: "sec-03-mirae.svg",
      bank_code: "238",
    },
    {
      name: "삼성",
      img: "sec-04-samsung.svg",
      bank_code: "240",
    },
    {
      name: "NH투자",
      img: "bank-01-NH.svg",
      bank_code: "247",
    },
    {
      name: "대신",
      img: "sec-06-daeshin.svg",
      bank_code: "267",
    },
    {
      name: "신한금융투자",
      img: "sec-07-shinhan.svg",
      bank_code: "278",
    },
    {
      name: "메리츠증권",
      img: "sec-08-meritz.svg",
      bank_code: "287",
    },
    {
      name: "유진투자",
      img: "sec-09-eugene.svg",
      bank_code: "280",
    },
    {
      name: "KB증권",
      img: "sec-10-kb.svg",
      bank_code: "218",
    },
    {
      name: "한국투자",
      img: "sec-11-hankook.svg",
      bank_code: "243",
    },
    {
      name: "교보",
      img: "sec-12-kyobo.svg",
      bank_code: "261",
    },
    {
      name: "하이투자",
      img: "sec-13-hi.svg",
      bank_code: "262",
    },
    {
      name: "현대차증권",
      img: "sec-14-hyundai.svg",
      bank_code: "263",
    },
    {
      name: "이베스트",
      img: "sec-15-ebest.svg",
      bank_code: "265",
    },
    {
      name: "SK",
      img: "sec-16-sk.svg",
      bank_code: "266",
    },
    {
      name: "한화투자",
      img: "sec-17-hanhwa.svg",
      bank_code: "269",
    },
    {
      name: "DB금융투자",
      img: "sec-18-db.svg",
      bank_code: "279",
    },
  ],
});

<template>
  <div
    class="container"
    style="padding: 0 30px 40px; flex: 1; display: flex; flex-direction: column"
  >
    <Step :phase="3"></Step>
    <div class="spacer" style="padding-top: 100px"></div>
    <div class="body-container" style="text-align: center; margin-bottom: 2rem">
      <v-img
        max-height="181"
        max-width="234"
        style="margin: 0 auto"
        src="@/assets/verify_success.svg"
      ></v-img>
      <h2 class="bank-title">본인 인증 완료</h2>
      <label class="bank-label"
        >입력하신 KYC 인증 정보 최종 검토 후 <br />
        SMS를 통해 승인 여부를 안내드리겠습니다.
      </label>
    </div>
    <div class="btn-container">
      <v-btn @click="onClickBack" block color="#373eff"> 확인 </v-btn>
    </div>
  </div>
</template>
<script>
import Step from "@/components/Step";

export default {
  name: "VerifySuccess",
  props: [],
  data() {
    return {};
  },
  components: {
    Step,
  },
  methods: {
    onClickBack() {
      //console.log('hh')
      this.$emit("cancel", { phase: -1 });
    },
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  flex: 1;
  .bank-title {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 42px;

    color: #373eff;
    margin-top: 30px;
    margin-bottom: 1.5rem;
  }
  .bank-label {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 26px;

    color: #363636;
  }
}

.btn-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  button {
    //size styling
    height: 60px !important;
    padding: 15px 16px;
    border-radius: 8px;

    //color styling
    background: #373eff;

    //text styling
    color: #fff;
    font-family: Noto Sans KR;
    font-style: normal;
    /* font-weight: 500; */
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 29px;
  }
}
</style>

<template>
  <div
    class="container"
    style="
      padding: 0 30px 40px;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
    "
  >
    <Step :phase="1" :items="phaseItems"></Step>
    <!-- <NumericKeyBoard /> -->
    <div class="openbank-spacer" style="padding-top: 80px"></div>
    <div style="text-align: center; margin-bottom: 2rem">
      <h2 class="bank-title">계좌 인증을 시작합니다.</h2>
      <label class="bank-label"
        >1원 송금을 받으실 은행 계좌를 선택하세요.</label
      >
    </div>

    <div
      class="text-center"
      style="display: flex; flex-direction: column; flex: 1"
    >
      <v-bottom-sheet scrollable v-model="sheet" inset>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="bank-select"
            :ripple="false"
            v-bind="attrs"
            v-on="on"
            @click="input1Validity = true"
            ref="bankSelectModal"
            style="
              width: 100%;
              height: 64px;
              padding: 1.5rem 1rem;
              display: flex;
              justify-content: flex-start;
              box-shadow: none;
              background: #ffffff;
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: normal;
              font-size: 1rem;
              line-height: 2rem;
              color: #363636;
            "
          >
            <v-img
              :transition="false"
              v-if="selectedItem !== -1"
              max-height="24px"
              max-width="24px"
              style="margin-right: 0.75rem"
              :src="require('@/assets/' + items[tab].items[selectedItem].img)"
            ></v-img>
            {{ bank }}
          </v-btn>
        </template>
        <v-sheet
          class="text-center bank-list-sheet"
          height="500px"
          width="100%"
        >
          <div class="bottom-sheet-handle" style=""></div>
          <v-card style="box-shadow: none">
            <v-tabs
              colors="#fff"
              v-model="tab"
              align-with-title
              style="margin-top: 24px; box-shadow: none"
            >
              <v-tabs-slider
                style="width: 100%"
                color="#373eff"
              ></v-tabs-slider>

              <v-tab
                :ripple="false"
                class="bank-tab"
                style="color: #868686"
                v-for="(item, idx) in items"
                :key="idx"
              >
                {{ item.title }}
              </v-tab>
            </v-tabs>
            <!-- <v-toolbar color="#FFF" dark flat>
              <template v-slot:extension>
              </template>
            </v-toolbar> -->
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(item, idx) in items" :key="idx">
                <v-card flat>
                  <ul class="bank-list-container" style="padding-left: 0px">
                    <li
                      v-for="(bank, idx) in item.items"
                      :key="idx"
                      style="padding: 15px 34px"
                      class="bank-list-item"
                      @click="onClickItem(bank, idx)"
                    >
                      <v-img
                        max-height="36"
                        max-width="36"
                        width="36px"
                        height="36px"
                        style="margin-bottom: 8px"
                        :transition="false"
                        :src="require('@/assets/' + bank.img)"
                      ></v-img>
                      <v-list-item-title v-text="bank.name"></v-list-item-title>
                    </li>
                  </ul>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
      <v-text-field
        placeholder="계좌번호 입력('-'제외)"
        class="bank-account-input"
        style="
          flex: 1;
          box-shadow: none;
          font-size: 1.25rem;
          letter-spacing: initial;
        "
        solo
        pattern="\d*"
        inputmode="numeric"
        type="number"
        v-model="sendData.account_num"
        outlined
        hide-details="true"
        @change="insertBankAccount"
      ></v-text-field>
      <!-- for realname
      <v-text-field
        placeholder="생년월일 입력(6자리)"
        class="bank-account-holder-info-input"
        style="
          flex: 1;
          box-shadow: none;
          font-size: 1.25rem;
          letter-spacing: initial;
        "
        solo
        pattern="\d*"
        inputmode="numeric"
        type="number"
        v-model="sendData.account_holder_info"
        outlined
        @change="insertBankAccountHolder"
      >
      </v-text-field>
      -->
      <div style="display: flex; position: sticky; bottom: 0px; left: 0px">
        <v-btn
          class=""
          color="#868686"
          @click="onClickBack"
          style="
            flex: 2;
            height: 60px;
            font-size: 20px;
            border-radius: 8px;
            background-color: #f6f6f6;
            margin-right: 4px;
          "
          text
        >
          취소
        </v-btn>
        <!--test 인 경우, click event method verify 로 바꾸기-->
        <v-btn
          class=""
          dark
          :loading="loading"
          :disabled="disabled"
          color="#373EFF"
          @click="send"
          style="
            flex: 3;
            font-size: 20px;
            color: #fff;
            height: 60px;
            border-radius: 8px;
            margin-left: 4px;
          "
        >
          1원 송금
        </v-btn>
        <v-dialog
          v-model="dialog.open"
          width="500"
          @click:outside="input1Validity = false"
        >
          <v-card>
            <v-card-text style="padding: 30px; text-align: center">
              <h2 class="modal-title" style="margin-bottom: 0.5rem">
                {{ dialog.title }}
              </h2>
              <p class="modal-contents">
                {{ dialog.contents1 }} <br />
                {{ dialog.contents2 }}
              </p>
              <v-btn
                class="modal-btn"
                block
                style="rgba(215, 216, 255, 1) !important"
                color="#373EFF"
                @click="onCloseModal"
                >{{ dialog.btnText }}</v-btn
              >
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import CONSTANTS from "@/constants";
import useb from "@/api/useb";
import Step from "../Step";
import EventBus from "@/api/EventBus";
import VueCookies from "vue-cookies";

export default {
  name: "BankSelect",
  props: ["phase", "phaseItems", "selectBank", "appData"],
  data() {
    return {
      disabled: true,
      dialog: {
        title: "시스템 오류",
        contents1:
          "시스템 오류로 계좌 확인에 실패하였습니다. 잠시 후 재시도 해주세요.",
        contents2: "잠시 후 재시도 해주세요.",
        btnText: "확인",
        open: false,
      },
      currentStep: 1,
      sheet: false,
      bank: "은행/증권사 선택",
      bankList: CONSTANTS.BANK_NAME,

      selectedItem: -1,
      tab: null,
      items: [
        { title: "은행", name: "bank", items: CONSTANTS.BANK_NAME },
        { name: "brokerage", title: "증권사", items: CONSTANTS.BROKERAGE },
      ],
      sendData: {
        bank_code: "",
        account_num: "",
        account_holder_name: "",
        account_holder_info: "",
      },
      loader: null,
      loading: false,
      input1Validity: false,
      input2Validity: false,
    };
  },

  created() {
    this.initialize();
    EventBus.$on("select", () => {
      this.sheet = true;
    });
  },
  mounted() {
    this.initialize();
    this.sheet = this.selectBank;
  },
  watch: {
    // selectedItem(val) {
    // },
    sendData: {
      deep: true,
      handler(val) {
        if (val.bank_code) {
          this.input1Validity = false;
        }
        if (val.account_num && val.bank_code) {
          this.disabled = false;
        }
        // for realname
        /*
        if (val.account_num && val.bank_code && val.account_holder_info) {
          this.disabled = false;
        }
        */
      },
    },
  },
  components: {
    Step,
  },
  methods: {
    initialize() {
      this.$set(
        this.sendData,
        "account_holder_name",
        this.appData.account_holder_name
      );
    },
    onClickItem(item, idx) {
      this.bank = item.name;
      this.sheet = false;
      this.selectedItem = idx;
      this.input1Validity = false;
      this.$set(
        this.sendData,
        "bank_code",
        this.items[this.tab].items[idx].bank_code
      );
    },
    onChange(e) {
      this.bankAccount = e;
    },
    onClickBack() {
      this.$emit("cancel", { phase: -1 });
    },

    onCloseModal() {
      this.$set(this.dialog, "open", false);
    },
    onConfirm() {
      this.dialog = !this.dialog;
      this.loading = true;
    },
    insertBankAccount(val) {
      this.$set(this.sendData, "account_num", val);
    },
    //for realname
    /*
    insertBankAccountHolder(val) {
      this.$set(this.sendData, "account_holder_info", val);
    },
    async verify() {
      try {
        this.loading = true;
        this.disabled = true;
        this.loader = this.loading;

        const res = await useb.verifyAccountHolder(this.sendData);
        if (res.account_holder_name === this.sendData.account_holder_name) {
          await this.send();
        } else {
          this.loading = false;
          this.disabled = false;
          this.$set(this.dialog, "title", "본인 인증 실패");
          this.$set(this.dialog, "contents1", "입력하신 계좌의 예금주와");
          this.$set(
            this.dialog,
            "contents2",
            "고객님의 성함이 일치하지 않습니다."
          );
          this.$set(this.dialog, "btnText", "재시도");
          this.$set(this.dialog, "open", true);
        }
      } catch {
        this.loading = false;
        this.disabled = false;
        this.$set(this.dialog, "title", "본인 인증 실패");
        this.$set(this.dialog, "contents1", "입력하신 계좌의 예금주와");
        this.$set(
          this.dialog,
          "contents2",
          "고객님의 성함이 일치하지 않습니다."
        );
        this.$set(this.dialog, "btnText", "재시도");
        this.$set(this.dialog, "open", true);
      }
    },*/
    async send() {
      try {
        if (!this.increaseSendCount()) {
          //console.log("송금취소");
          window.alert("테스트 제한횟수를 초과하였습니다.");
          this.disabled = true;
          return;
        }
        this.loading = true;
        this.disabled = true;
        this.loader = this.loading;

        const res = await useb.sendOpenbank(this.sendData);
        this.loading = false;
        this.disabled = false;
        this.$emit("next", {
          account_num: this.sendData.account_num,
          transaction_id: res.transaction_id,
          bankIdx: this.selectedItem,
          tabIdx: this.tab,
        });
      } catch (err) {
        this.loading = false;
        this.disabled = false;
        const { errorCode } = err;

        if (errorCode === "S011" || errorCode === "S012") {
          this.$set(this.dialog, "title", "본인 인증 실패");
          this.$set(this.dialog, "contents1", "입력하신 계좌의 예금주와");
          this.$set(
            this.dialog,
            "contents2",
            "고객님의 성함이 일치하지 않습니다."
          );
          this.$set(this.dialog, "btnText", "재시도");
          this.$set(this.dialog, "open", true);
        } else {
          this.$set(this.dialog, "title", "시스템 오류");
          this.$set(
            this.dialog,
            "contents1",
            "시스템 오류로 1원 송금에 실패하였습니다."
          );
          this.$set(this.dialog, "contents2", "잠시 후 재시도 해주세요.");
          this.$set(this.dialog, "btnText", "확인");
          this.$set(this.dialog, "open", true);
        }
      }
    },
    // 사용횟수 체크하는 로직
    increaseSendCount: () => {
      // 만약 쿠키가 이미 있다면 숫자 늘리기
      if (VueCookies.get("openbank_usage")) {
        if (VueCookies.get("openbank_usage") >= 5) {
          //console.log("테스트 사용횟수를 전부 소진하였습니다.");
          return false;
        }
        VueCookies.set(
          "openbank_usage",
          1 * VueCookies.get("openbank_usage") + 1
        );
        //console.log("사용횟수", VueCookies.get("openbank_usage"));
        return true;
      } else {
        VueCookies.set("openbank_usage", 1);
        return true;
      }
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 800px) {
  .container {
    width: 800px !important;
    margin: auto !important;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  opacity: 1;
}

.bank-title {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;

  color: #363636;
}
.bank-label {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #868686;
}

.bank-select.focus {
  border-color: #5f65ff;
}
.theme--light.bank-select.v-btn:focus::before {
  opacity: 0;
}
.bank-select:focus {
}

.v-btn__content {
  font-size: 1.25rem;
  letter-spacing: initial;
}

.bank-select,
bank-account-input,
bank-account-holder-info-input {
  background: #ffffff;
  background-color: #ffffff;
  color: #363636;
  height: 64px;

  /* Primary/80% */

  border: 1px solid #ced1d2;
  /* 2 dp, Primary */

  box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1),
    0px 2px 8px 4px rgba(95, 101, 255, 0.05);
  border-radius: 8px;
}

.bank-account-input.focus {
  .v-input__slot.focus {
    border: 1px solid #373eff !important;
  }
}
.v-window.v-item-group.v-tabs-items {
  padding-top: 20px;
  height: 420px;
  overflow-y: scroll;
}
.v-text-field--outlined fieldset {
  border: 1px solid #ced1d2 !important;
}
.v-text-field--outlined.focus fieldset {
  border: 1px solid #5f65ff !important;
}
.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid #5f65ff !important;
}
.v-input__slot {
  margin-top: 1rem;
  /* border: 1px solid #ced1d2; */
  border-radius: 8px;
  box-shadow: none !important;
}
.bank-list-sheet {
  padding-top: 1rem;
  /* padding-left: 1rem;
  padding-right: 1rem; */
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.v-tabs .bank-tab {
  width: 50%;
  letter-spacing: 0;
  font-size: 1.25rem;
  margin-left: 0 !important;
  max-width: none;
  &::before {
    display: none;
  }
  &:focus {
    background: #fff;
  }
  &:hover {
    background: #fff;
  }
}
.v-tab--active {
  color: #373eff !important;
}

.bank-list-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px !important;
}
.bank-list-item {
  display: flex;
  flex-direction: column;
  width: 104px;
  height: 104px;
  align-items: center;
  width: 25%;
  .v-list-item__title {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    color: #363636;
  }
}
.bottom-sheet-handle {
  height: 4px;
  width: 60px;
  border-radius: 2px;
  background: #c4c4c4;
  margin: 0 auto;
}

/* Modal Css */
.modal-title {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #373eff;
}
.v-card .v-card__text .modal-contents {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 2rem;
  line-height: 26px;
  color: #363636;
}
.modal-btn {
  padding: 15px 16px;
  height: 60px !important;
  color: #fff !important;
  background: #373eff;
  font-size: 20px;
  border-radius: 8px;
  span {
    font-size: 20px;
  }
}
.v-btn__loader {
  color: #fff;
  background: #373eff;
  border-radius: 8px;
}
.v-input__slot {
  height: 64px;
}
div .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(215, 216, 255, 1) !important;
  color: #ffffff !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-container"},[_c('div',{class:{ prev: _vm.phase > 0, current: _vm.phase === 0, next: _vm.phase < 0 }},[_c('div',{staticClass:"text-info"},[_vm._v("정보입력")])]),_c('div',{class:{
      'line-prev': _vm.phase > 1,
      'line-current': _vm.phase === 1,
      'line-next': _vm.phase < 1,
    }}),_c('div',{class:{ prev: _vm.phase > 1, current: _vm.phase === 1, next: _vm.phase < 1 }},[_c('div',{staticClass:"text-info"},[_vm._v("계좌 송금")])]),_c('div',{class:{
      'line-prev': _vm.phase > 2,
      'line-current': _vm.phase === 2,
      'line-next': _vm.phase < 2,
    }}),_c('div',{class:{ prev: _vm.phase > 2, current: _vm.phase === 2, next: _vm.phase < 2 }},[_c('div',{staticClass:"text-info"},[_vm._v("계좌 인증")])])])}
var staticRenderFns = []

export { render, staticRenderFns }